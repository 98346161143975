export const AVAILABILITY = {
  delivery: "Delivery",
  pickup: "Pickup",
  pickupDelivery: "Pickup & Delivery",
};

/* eslint-disable sort-keys-fix/sort-keys-fix -- order matters for display */
// This relies on the sort order of the json to render presentational components in the correct order
export const CATEGORY_NAMES: { [category: string]: string } = {
  Flower: "Flower",
  Concentrate: "Concentrate",
  Edible: "Edible",
  Cartridge: "Cartridge",
  PreRoll: "Pre-roll",
  Topical: "Topical",
  Accessory: "Accessory",
  Other: "Other",
};
/* eslint-enable sort-keys-fix/sort-keys-fix -- order matters for display  */

export const DISPENSARY_PLACEHOLDER_PHOTO =
  "https://leafly-public.imgix.net/dispensary/static/placeholder-dispensary-photo.jpg";

export const DISPENSARY_PAGES = {
  about: "about",
  appointment: "appointment",
  deals: "deals",
  main: "main",
  menu: "menu",
  photos: "photos",
  reviews: "reviews",
};

export const DISPENSARY_TABS_US = {
  [DISPENSARY_PAGES.main]: {
    href: "/dispensary-info/[slug]",
    page: DISPENSARY_PAGES.main,
  },
  [DISPENSARY_PAGES.menu]: {
    href: "/dispensary-info/[slug]/menu",
    page: DISPENSARY_PAGES.menu,
  },
  [DISPENSARY_PAGES.deals]: {
    href: "/dispensary-info/[slug]/deals",
    page: DISPENSARY_PAGES.deals,
  },
  [DISPENSARY_PAGES.reviews]: {
    href: "/dispensary-info/[slug]/reviews",
    page: DISPENSARY_PAGES.reviews,
  },
};

export const CBD_STORE_TABS = {
  [DISPENSARY_PAGES.main]: {
    href: "/cbd-store/[slug]",
    page: DISPENSARY_PAGES.main,
  },
  [DISPENSARY_PAGES.menu]: {
    href: "/cbd-store/[slug]/menu",
    page: DISPENSARY_PAGES.menu,
  },
  [DISPENSARY_PAGES.deals]: {
    href: "/cbd-store/[slug]/deals",
    page: DISPENSARY_PAGES.deals,
  },
  [DISPENSARY_PAGES.reviews]: {
    href: "/cbd-store/[slug]/reviews",
    page: DISPENSARY_PAGES.reviews,
  },
};

export const DISPENSARY_TABS_CA = {
  [DISPENSARY_PAGES.main]: {
    href: "/cannabis-store/[slug]",
    page: DISPENSARY_PAGES.main,
  },
  [DISPENSARY_PAGES.menu]: {
    href: "/cannabis-store/[slug]/menu",
    page: DISPENSARY_PAGES.menu,
  },
  [DISPENSARY_PAGES.deals]: {
    href: "/cannabis-store/[slug]/deals",
    page: DISPENSARY_PAGES.deals,
  },
  [DISPENSARY_PAGES.reviews]: {
    href: "/cannabis-store/[slug]/reviews",
    page: DISPENSARY_PAGES.reviews,
  },
};

export const DOCTOR_TABS = {
  [DISPENSARY_PAGES.about]: {
    href: "/doctors/[slug]",
    page: DISPENSARY_PAGES.about,
  },
  [DISPENSARY_PAGES.reviews]: {
    href: "/doctors/[slug]/reviews",
    page: DISPENSARY_PAGES.reviews,
  },
  [DISPENSARY_PAGES.appointment]: {
    href: "/doctors/[slug]/appointment",
    page: DISPENSARY_PAGES.appointment,
  },
};

export const DISPENSARY_FILTER_STRINGS: Record<string, string> = {
  "cbd_content:asc": "CBD: Low",
  "cbd_content:desc": "CBD: High",
  "created:asc": "Oldest",
  "created:desc": "Newest",
  delivery_available: AVAILABILITY.delivery,
  each: "Each",
  g: "Gram",
  "menu_item_name:asc": "A-Z",
  mg: "Milligram",
  oz: "Ounce",
  pc: "Piece",
  pickup_available: AVAILABILITY.pickup,
  pickup_delivery_available: AVAILABILITY.pickupDelivery,
  "price:asc": "Price: Low",
  "price:desc": "Price: High",
  "thc_content:asc": "THC: Low",
  "thc_content:desc": "THC: High",

  "upvotes_count:asc": "Least Helpful",
  "upvotes_count:desc": "Most Helpful",
};

export const DISPENSARY_NEW_FILTER_STRINGS: Record<string, string> = {
  "cbd_content:asc": "CBD: low to high",
  "cbd_content:desc": "CBD: high to low",
  "price:asc": "Price: low to high",
  "price:desc": "Price: high to low",
  "thc_content:asc": "THC: low to high",
  "thc_content:desc": "THC: high to low",
};

export const DISPENSARY_DEFAULT_MAX_DISTANCE = 30;
export const DISPENSARY_EXPANDED_MAX_DISTANCE = 150;
export const DISPENSARY_FILTER_NAMES: { [key: string]: boolean } = {
  brand_name: true,
  cbd: true,
  cbd_pips: true,
  cbd_pips_max: true,
  cbd_pips_min: true,
  deal_title: true,
  filterable_price: true,
  has_cbd_submitted: true,
  is_brand_verified: true,
  is_staff_pick: true,
  normalized_quantity: true,
  page: true,
  pickup_available: true,
  price: true,
  price_max: true,
  price_min: true,
  product_category: true,
  q: true,
  sort: true,
  strain_category: true,
  strain_effects_included: true,
  strain_name: true,
  strain_top_terp: true,
  thc: true,
  thc_pips: true,
  thc_pips_max: true,
  thc_pips_min: true,
  top_strain_effects_included: true,
  top_strain_negatives_excluded: true,
  unit: true,
  whats_hot: true,
};

export const MSO_DISPENSARY_SLUGS = [
  "curaleaf",
  "jars-cannabis",
  "insa",
  "sunnyside",
];
