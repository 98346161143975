import { type AxiosError } from "axios";

import serviceRequest from "api/serviceRequest";
import publicConfig from "config/public";
import formatAndReturnRejectedError from "utils/formatAndReturnRejectedError";

// Initialize the service request with the app and environment
const complianceApi = serviceRequest();

// Configure and set upstream URL
complianceApi.defaults.baseURL = publicConfig.complianceApiUrl;

complianceApi.interceptors.response.use(
  (response) => response,
  (error: AxiosError<{ status: number; message?: string }>) =>
    formatAndReturnRejectedError(
      error,
      error.response?.data?.message,
      error.response?.data?.status,
    ),
);
export default complianceApi;
