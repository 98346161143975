export const toTitleCase = (str = "") =>
  str.replace(/./, (m) => m.toUpperCase());

export const getPaymentMethodsString = (
  acceptedPayments: Record<string, boolean>,
  paymentMethods: string[] = [],
) => {
  // hardcoding options to ensure consistent display order
  const defaultPickupPayment = ["cash", "credit", "debit"].filter(
    (method) => acceptedPayments[method],
  );

  if (!paymentMethods || !paymentMethods.length) {
    paymentMethods = defaultPickupPayment.length
      ? defaultPickupPayment
      : ["cash"];
  }
  const appendCardStr = (m = "") =>
    toTitleCase(/credit/i.test(m) ? m + " Card" : m);
  const str = paymentMethods.map(appendCardStr).join(", ");
  return str;
};

export const formatDeliveryFee = (fee?: number) => {
  if (fee === undefined) {
    return "";
  }
  return Number(fee) === 0 ? "Free" : `$${fee}`;
};

export const formatDeliveryMin = (min?: number) => {
  if (min === undefined) {
    return "";
  }
  return Number(min) === 0 ? "No minimum" : `$${min} minimum`;
};
