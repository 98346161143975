import complianceApi from "api/services/compliance";
import { DeliveryZoneDecisionResponse } from "custom-types/Compliance";
import { Location } from "custom-types/Location";
import logError from "utils/logError";

const fallbackResponse = {
  result: false,
  violations: [
    {
      message: "Unable to validate user's address for delivery.",
      type: "string",
    },
  ],
};

const getDeliveryZoneFromAddress = async (
  dispensaryId: number,
  customerAddress: Location,
): Promise<DeliveryZoneDecisionResponse> => {
  try {
    const { data } = await complianceApi.post(
      `/v2/dispensary/${dispensaryId}/delivery/decision/address`,
      {
        customerAddress,
      },
    );

    if (!data) {
      return fallbackResponse;
    }

    return data;
  } catch (e) {
    logError(e.message, {
      functionName: "getDeliveryZoneFromAddress",
      service: "compliance",
      statusCode: e.statusCode,
    });
    return fallbackResponse;
  }
};

export default getDeliveryZoneFromAddress;
